import React from 'react';
import {
    Container,
    Text,
    SimpleGrid,
    Card,
    useMantineTheme,
    // Type needed if you want to be explicit with breakpoints:
} from '@mantine/core';
import { useConfig } from '../ConfigContext';

// Adjust if your data differs
interface PrivacySection {
    title: string;
    paragraphs: string[];
}

const Privacy: React.FC = () => {
    const theme = useMantineTheme();
    // Make sure `useConfig()` returns something like { privacy: PrivacySection[] }
    const { privacy } = useConfig();

    return (
        <Container size="xl" py="xl">
            <Text size="xl" mb={theme.spacing.xl}>
                {privacy.intro}
            </Text>

            {/* Use the breakpoints prop (Mantine 5+) */}
            <SimpleGrid cols={3} spacing="xl">
                {privacy.sections.map((section: PrivacySection, index: number) => (
                    <Card key={index} withBorder radius="md" p="md" shadow="sm">
                        <Text
                            style={{
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: '20px',
                                fontWeight: 600,
                                marginBottom: theme.spacing.md,
                            }}
                        >
                            Section {index + 1}: {section.title}
                        </Text>
                        {section.paragraphs.map((para: string, idx: number) => (
                            <Text
                                key={idx}
                                style={{
                                    fontFamily: 'Greycliff CF, sans-serif',
                                    fontSize: '14px',
                                    lineHeight: 1.5,
                                    marginBottom: theme.spacing.sm,
                                }}
                            >
                                {para}
                            </Text>
                        ))}
                    </Card>
                ))}
            </SimpleGrid>
        </Container>
    );
};

export default Privacy;