// ConfigContext.tsx
import React, {createContext, useContext, useState, useEffect, useCallback} from "react";
import {config as initialConfig} from './portalConfig';  // Import your configuration

export type TabItem = {
    link: string;
    label: string;
    wide?: boolean;
    private?: boolean;
    disabled?: boolean;
    icon: React.ElementType;
};

type TabSection = {
    label: string;
    items: TabItem[];
    private?: boolean;
    disabled?: boolean;
    default?: boolean; // Optional boolean to indicate the default section
};

type Tabs = {
    [key: string]: TabSection;
};

type Features = {
    icon?: React.ElementType;
    title: string;
    description?: string;
}

type ConsultingOffering = {
    icon?: React.ElementType;
    title: string;
    description?: string;
    valuePoints: string[];
}

type FeaturesAndBenefitsOffering = {
    icon?: React.ElementType;
    title: string;
    description?: string;
    valuePoints: string[];
}

type FeatureDefinition = {
    name: string;
    available?: boolean;
    description?: string;
}

type PlanFeature = {
    name: string;
    value: string;
    description?: string;
}

type TaskCategory = {
  name: string;
  imageName: string;
  title: string;
  description: string;
}

type Plan = {
    name: string;
    favorite?: boolean;
    available: boolean;
    features: PlanFeature[];
    price: {
        monthly: number;
        annual: number;
    };
    stripe_prices: {
        monthly: string;
        annual: string;
    };
}

type CreditPackage = {
    productId: string;
    credits: number;
}

type TeamMember = {
    photo: string;
    name: string;
    title: string;
    intro: string;
    experience?: string[];
}

type Home = {
    image: string;
    title: string;
    body: string;
    features: Features[]
}

export type Category = {
    categoryId: string;
    title: string;
    info?: string;
    image?: string;
    icon?: React.ElementType;
}

type Author = {
    name: string;
    avatar?: React.ElementType;
    photo?: string;
}

export type NewsCategories = {
    [key: string]: Category;
}

export type DemosCategories = {
    [key: string]: Category;
}

export type NewsItem = {
    categoryId: string;
    assetId: string;
    date?: string;
    title: string;
    image?: string;
    abstract?: string;
    intro: string;
    keyPoints: string[];
    cta: string;
    conclusion: string;
    author?: string;
}

export type DemosItem = {
    categoryId: string;
    assetId: string;
    date?: string;
    title: string;
    image?: string;
    abstract?: string;
    author?: string;
}


type FAQ = {
    question: string;
    answer: string;
    icon?: React.ElementType;
}

type Section = {
    title: string;
    paragraphs: string[];
}

type Privacy ={
    intro: string[];
    sections: Section[];
}

type Contact = {
    title: string;
    description: string;
    icon: React.ElementType;
}

type API = {
    baseUrl: string;
}

type ConfigType = {
    api: API;
    categories: TaskCategory[];
    navigationLinks: Tabs;
    home: Home;
    consulting: ConsultingOffering[];
    featuresAndBenefits: FeaturesAndBenefitsOffering[];
    team: TeamMember[];
    authors: Author[];
    newsCategories: NewsCategories;
    demosCategories: DemosCategories;
    news: NewsItem[];
    demos: DemosItem[];
    faqs: FAQ[];
    privacy: Privacy;
    contacts: Contact[];
    allFeatures: FeatureDefinition[];
    plans: Plan[];
    priceOrder: string[];
    creditPackages: CreditPackage[];
};

export const ConfigContext = createContext<ConfigType | null>(null);

export const useConfig = () => {
    const context = useContext(ConfigContext);
    if (!context) throw new Error("useConfig must be used within a ConfigProvider");
    return context;
};

interface ConfigProviderProps {
    children: React.ReactNode;
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({children}) => {
    const [config, setConfig] = useState<ConfigType>(initialConfig); // This should now be correctly typed

    useEffect(() => {
        // Here you can fetch remote config and merge or replace it
        // For example, this could be replaced with remote fetching logic
        setConfig(initialConfig);
    }, []);

    return (
        <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
    );
};
